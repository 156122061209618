<template>
    <TheInvitationModal message="Accepting Invitation" :show="show" />
</template>
<script>
const TheInvitationModal = () => import('../components/TheInvitationModal')

export default {
    mounted() {
        this.$data.show = true
        this.$axios
            .get(`/invitations/accept/${this.$route.params.invid}`, {
                timeout: 300000
            })
            .then(response => {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Invitation accepted!',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
                this.$data.show = false
                this.$router.push({ name: 'home-welcome' })
                this.$store.dispatch('fetchUserOrgs', true)
            })
            .catch(error => {
                if (!error.response || !error.response.data || error.response.data.code !== 'already_accepting') {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to accept the invitation.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                }
                this.$data.show = false
            })
    },
    components: { TheInvitationModal },
    data() {
        return {
            show: false
        }
    }
}
</script>
